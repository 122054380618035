#map-canvas {
  height: 100%;
}

.select2-selection__rendered {
  line-height: 40px !important;
}

.select2-selection {
  min-height: 44px !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 44px;
}

.select2-container .select2-selection--single {
  height: 46px !important;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 40px !important;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 40px !important;
}
.select2-container--default .select2-selection--single {
  border: 1px solid #e0e0e0 !important;
}

.select2-container .select2-search--inline .select2-search__field {
  margin-top: -2px !important;
  color: #2c3e50;
  padding-left: 12px !important;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
}

.select2-container {
  width: 100% !important;
}

.dark .select2-container--default .select2-selection--single {
  background: #222 !important;
  border-color: #252525 !important;
}

.dark
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: #fff !important;
}

.dark .select2-search.select2-search--dropdown {
  background: #252525 !important;
}

.dark
  .select2-container--default
  .select2-search--dropdown
  .select2-search__field {
  background: #252525 !important;
  color: #fff !important;
}
.dark .select2-container--open .select2-dropdown--below {
  border-color: #252525;
}

.pr-10 {
  padding: 0 0 0 10px;
}

.attachment-image {
  height: 80px;
  width: 80%;
}

.attachment-file {
  height: 80px;
  width: 50%;
}

.remove-file {
  position: absolute;
  /* right: 0; */
  top: 0;
}

.notification_tag {
  background: #e3342f;
  color: #fff;
  font-size: 12px;
  border-radius: 100px;
  line-height: normal;
  /* height: 18px;
    width: 20px; */
  font-weight: bold;
  text-align: center;
  display: inline-block;
}

.nav-item span.count-mail {
  height: 22px !important;
  width: 22px !important;
  font-size: 12px;
  line-height: 20px !important;
  right: 0px;
  top: 10px;
}

h6 .notification_count {
  font-weight: 400;
}

.notification-height {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 325px;
}

.mm-top-navbar .notification-menu {
  min-width: 360px;
}

.notify-list-bg {
  background: #ecf0f5;
}
.navbar-list li .mm-sub-dropdown .sub-card {
  font-size: inherit;
  padding: 15px;
  line-height: normal;
  color: var(--mm-default);
  border-bottom: 1px solid #e7e7e8;
  display: inline-block;
  width: 100%;
}

.dark .notify-list-bg {
  background: #222222;
}

.card-body.activity-height {
  max-height: 368px !important;
  overflow-x: hidden !important;
}

.text-green-600 {
  color: rgba(5, 150, 105, 1);
}

.language_table tbody {
  display: block;
  max-height: 450px;
  overflow-y: scroll;
}

.language_table thead,
.language_table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.mm-top-navbar .language-menu ul {
  max-height: 350px;
  overflow: scroll;
}

.selected-lang {
  height: 30px !important;
  min-width: 30px !important;
  width: 30px !important;
}
.selected-lang-list {
  width: 20px !important;
  height: auto !important;
  min-width: 15px !important;
}

.iti {
  width: 100% !important;
}

.dark .iti__country-list {
  background-color: #222;
  border: 1px solid #252525;
}

.profile-box {
  /* padding-bottom: 170px !important; */
}

.chat {
  float: left;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  width: 100%;
  /* background: #F2F5F8;
    color: #434651; */
}

.chat .chat-history {
  padding: 30px 30px 20px;
  border-bottom: 2px solid white;
  overflow-y: scroll;
  height: 575px;
}
.chat .chat-history .message-data {
  margin-bottom: 15px;
}

.chat .chat-history .message-data-time {
  padding-left: 6px;
}

.chat .chat-history .message {
  padding: 18px 20px;
  line-height: 26px;
  font-size: 16px;
  border-radius: 7px;
  margin-bottom: 30px;
  width: 90%;
  position: relative;
}

.chat .chat-history .message:after {
  bottom: 100%;
  left: 7%;
  border: solid transparent;

  height: 0;
  width: 0;
  position: absolute;

  border-width: 10px;
  margin-left: -10px;
}

.other-message {
  background: var(--info);
}

.my-message {
  background: var(--primary);
}
.my-message:after {
  border-bottom-color: var(--primary);
  left: 93%;
}

.chat-history ul {
  padding: 20px;
  /* height: 770px; */
}

.chat-history ul > li {
  padding-bottom: 20px;
  list-style: none;
}

.li-clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

#maplegend {
  font-family: Arial, sans-serif;
  background: #fff;
  padding: 10px;
  margin: 10px;
  color: #222;
}

#maplegend img {
  vertical-align: middle;
}

.map_driver_detail {
  font-family: Arial, sans-serif;
  background: #fff;
  color: #222;
}
/*
.dark .mode.light-img {
    display: none;
}

.dark .mode.dark-img {
    display: block;
} 
  
.mode {
    display: none;
}

.mode.light-img {
    display: block;
}

.mode.ltr-img {
    display: block;
}
*/

/* Custom font size classes */
