body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.accordion-header button {
  width: 100% !important;
  text-align: left !important;
  padding: 10px !important;
  font-size: 18px !important;
  font-weight: 400 !important;
}

.accordion-button:not(.collapsed) {
  color: black !important;
  background-color: #e8eef3 !important;
}

a {
  text-decoration: none !important;
}

.accordion-button::after {
  flex-shrink: 0;
  width: 10px;
  height: 10px;
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}

.cursor-pointer {
  cursor: pointer !important;
}

@media (max-width: 991.98px) {
  .main-sidebar {
    transform: translate3d(-293px, 0, 0) !important;
  }

  .main-sidebar-overlay {
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    backdrop-filter: blur(2px);
  }

  .main-sidebar.open {
    transform: translate3d(0, 0, 0) !important;
  }
}

.bg-orange {
  background: #f69b31 !important;
}
.react-loader-spinner > div {
  width: fit-content;
  margin: auto;
}
.dropdown-sidebar {
  height: 0;
  overflow: hidden;
  transition: all;
  /* transition-duration: 5s; */
}

.dropdown-sidebar.show {
  height: 100%;
}
.main-sidebar .parent-nav.open {
  background: #111827;
}
.bg-brand {
  background-color: #00a699 !important;
  border-radius: 5px;
}
.text-brand {
  color: #00a699 !important;
}
.fs-1 {
  font-size: 14px; /* Adjust the font size as needed */
}

.fs-2 {
  font-size: 16px; /* Adjust the font size as needed */
}

.fs-3 {
  font-size: 20px; /* Adjust the font size as needed */
}

.fs-4 {
  font-size: 24px; /* Adjust the font size as needed */
}

.fs-5 {
  font-size: 28; /* Adjust the font size as needed */
}

.fs-6 {
  font-size: 32px; /* Adjust the font size as needed */
}

.phone-input {
  width: 100% !important;
}
.phone-input input {
  width: 100% !important;
  background: #f7f9ff !important;
  border: 1px solid #deeaff !important;
  border-radius: 4px !important;
  box-shadow: none !important;
  color: #535f6b !important;
  font-size: 14px !important;
  height: 45px !important;
  line-height: 45px !important;
}

.dark .phone-input input {
  width: 100% !important;
  background: #222222 !important;
  border: 1px solid #deeaff !important;
  border-radius: 4px !important;
  box-shadow: none !important;
  color: white !important;
  font-size: 14px !important;
  height: 45px !important;
  line-height: 45px !important;
}

.dark .react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: #222222 !important;
  border: 1px solid #cacaca;
  border-radius: 3px 0 0 3px;
}

.fullscreen-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 1); /* Dark overlay for better visibility */
  display: flex;
  align-items: center;
  justify-content: center;
}
.fullscreen-container .close-btn {
  display: block !important;
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  background-color: transparent;
  border: none;
  outline: none;
}

.fullscreen-container img {
  /* max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  width: 500px; */
  object-fit: contain;
  width: fit-content;
  height: 100%;
}

.fullscreen-containertwo {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 1); /* Dark overlay for better visibility */
  display: flex;
  align-items: center;
  justify-content: center;
}
.fullscreen-containertwo .close-btn {
  display: block !important;
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  background-color: transparent;
  border: none;
  outline: none;
}
.fullscreen-containertwo img {
  object-fit: contain;
  width: fit-content;
  height: 100%;
}

/* .shadow_box {
  -moz-box-shadow: 1px 3px 21px 1px rgba(0, 0, 0, 0.43);
  -webkit-box-shadow: 1px 3px 21px 1px rgba(0, 0, 0, 0.43);
  box-shadow: 1px 3px 21px 1px rgba(0, 0, 0, 0.43) !important;
} */

.object-fit-contain {
  object-fit: contain;
}

.application_texts {
  color: black;
  font-weight: 500;
}

.accordion-header button {
  width: 100% !important;
  text-align: left !important;
  padding: 10px !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  background-color: dodgerblue !important;
  color: white !important;
  border: 1px solid dodgerblue !important;
  border-radius: 6px;
  margin-bottom: 10px;
}

.accordion-button:not(.collapsed) {
  background-color: dodgerblue !important;
  color: white !important;
  border: 1px solid dodgerblue !important;
  border-radius: 6px;
}

.react-image-lightbox .ril-loading-circle {
  display: none !important;
}
.modal_changes .modal-dialog .modal-content {
  background-color: transparent;
  border: none;
}
